#modifiedHeightForContact {
  height: 93.3vh;
}

.modifiedWrapperForContact {
  border: 1px solid blue;
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 93.3vh;
}

.lumareOnTheMap {
  border-style: none;
  border-radius: 5px;
  width: 100%;
}

/* .testWidth
  {
    border: 1px solid red;
    width: 100%;
   
  } */
