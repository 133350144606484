.app__navbar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 2rem;
  background: var(--color-black);
  color: white;
}

.app_navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 15px;
}

.app_navbar-logo img {
  height: 28px;
}

.navbar-restaurant_name {
  font-size: 20.5px;
  font-family: "Aboreto", cursive;
  display: flex;
  align-items: center;
}

.app_navbar-links {
  display: flex;
  justify-content: end;
  align-items: center;
  /* gap: 15px; */
  flex: 1;
  list-style: none;
}

#activePathh {
  font-size: 17.5px;
  color: rgb(219, 172, 71);
}

.app_navbar-links li {
  margin: 0 1rem;
  cursor: pointer;
  font-size: 16.5px;
  transition: ease-in 300ms;
}

.hoverEffectNav {
  color: white;
  display: inline-block;
  margin: 0;
}
.hoverEffectNav:after {
  display: block;
  content: "";
  border-bottom: solid 3px rgb(219, 172, 71);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.hoverEffectNav:hover:after {
  transform: scaleX(1);
}
.hoverEffectNav.fromRight:after {
  transform-origin: 100% 50%;
}
.hoverEffectNav.fromLeft:after {
  transform-origin: 0% 50%;
}

.app_navbar-smallscreen {
  flex: 1;
  justify-content: end;
  display: none;
}

.app_navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--color-black);
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 20;
}

.app_navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: rgb(219, 172, 71);
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.app_navbar-smallscreen-links {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 21.5px;
  font-family: var(--font-base);
}
#activePathhMobile {
  color: rgb(219, 172, 71);
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .app_navbar-links {
    display: none;
  }

  .app_navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 550px) {
  .app__navbar {
    justify-content: space-around;
    margin-top: -1px;
    padding: 10px 8px;
    /* border: 1px solid red; */
  }
  .app_navbar-smallscreen {
    flex: 0;
  }
  .app_navbar-logo {
    margin-right: 0px;
  }
  .navbar-restaurant_name {
    font-size: 18.5px;
  }
  .menuMenu
  {
    font-size: 1.7em;
  }
}
