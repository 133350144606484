#test {
  /* border: 5px solid red; */
  min-height: 94vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuWrapper {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 2px solid white;
  border-radius: 10px;
  width: 100%;
  min-width: 300px;
  max-width: 550px;
  /* min-height: 600px; */
  background-color: rgba(15, 3, 40, 0.73);
  color: white;
  font-size: 18.5px;
  /* background: url('../assets/images/menuBackwob.png');

   
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  padding: 5px;
}

.menu {
  /* border: 1px solid red; */
  /* min-height: 600px; */
}

.menuHeader {
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
  /* background-color: rgba(242, 238, 238, 0.583); */
  display: flex;
}

.goBack {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  font-size: 23px;
}

.goBack:hover {
  color: var(--color-golden);
  transform: scale(1.3);
}

.language {
  text-align: center;
  padding-top: 15px;
}

.menuMenu {
  /* border: 1px solid white; */
  text-align: center;
  flex: 1;
}

.currentOpen {
  width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  /* border: 1px solid red; */
}

.menuLogoTopRightCorner {
  /* border: 1px solid red; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  justify-content: center;
  width: 95px;
  padding-bottom: 4px;
}

.menuLogoTopRightCorner img {
  height: 34px;
}

.selectorGeneral {
  /* border: 1px solid yellow; */
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  padding: 5px;
  gap: 15px;
}

#customSetMenuButtonsWidth {
  width: 150px;
}

.menuItems5 {
  /* border: 2px solid yellow; */
  margin-top: 50px;
  margin-bottom: 5px;
  padding: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.menuCardRowWrapper {
  /* border: 1px solid blue; */
  margin: 8px 0;
}

.menuItemsCardCustom {
  /* border: 3px solid blue; */
  display: flex;
  justify-content: space-between;
  /* margin: 6px 0; */
  gap: 8px;
  align-items: flex-end;
  font-size: 19.5px;
  margin-bottom: 1.5px;
}
.listMenuItemName {
  /* border: 1px solid red; */
  align-self: flex-end;
  height: 20px;
}

.dottedOnMenuItems {
  flex: 1;
  border-bottom: 1px dotted white;
  margin-bottom: 1.5px;
}

.priceOfItem {
  border: 1px solid var(--color-golden);
  width: 70px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 235, 205, 0,9); */
}

.detailsInMenuRow {
  /* border: 1px solid blue; */
  font-size: 17px;
  padding-left: 25px;
  color: var(--color-golden);
}

.exploreMenuButtonsMenu {
  border: 2px solid white;
  font-size: 16.5px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: transparent;
  border-radius: 15px;
  transition: 0.3s ease-in;
  color: white;
  cursor: pointer;
}

@media screen and (min-width: 625px) {
  .exploreMenuButtonsMenu:hover {
    color: black;
    background-color: aquamarine;
  }
}

.exploreMenuButtonsMenu:active {
  color: white;
}

@media screen and (max-width: 415px) {
  .listMenuItemName {
    list-style: none;
  }
  .detailsInMenuRow {
    padding-left: 0px;
  }
}

@media screen and (max-width: 475px) {
  .menuItemsCardCustom {
    font-size: 17.5px;
    /* border: 1px solid red; */
  }
  .detailsInMenuRow {
    font-size: 15.5px;
  }
  .priceOfItem {
    width: 56px;
  }
}

@media screen and (max-width: 625px) {
  #test {
    /* align-items:inherit; */
    align-items: baseline;
  }
  .menuWrapper {
    margin-top: 65px;
    margin-bottom: 25px;
  }
  .menuItems5 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .menuItemsCardCustom {
    /* border: 1px solid red; */
    gap: 4px;
  }
  .menuItemsCardCustom {
    flex-wrap: wrap;
    justify-content: end;
  }
}

.app__MenuBg {
  background: rgb(217, 213, 213);
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  /* background-attachment: fixed; */
}

@media screen and (min-width: 625px) {
  .app__MenuBg {
    background-attachment: fixed;
  }
}
