@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap");

.home {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.showcase {
  /* border: 2px solid red; */
  position: relative;
  height: 93.3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--primary-color) url("../assets/images/lumare_front.jpg")
    no-repeat center center/cover;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 5%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.content {
  z-index: 10;
  color: white;
  font-size: 26px;
  /* border: 3px solid green; */
  position: absolute;
  bottom: 25px;
}

.welcomeWrapper {
  display: flex;
  gap: 20px;
}

.star {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  padding-top: 6px;
  font-size: 40px;
  color: rgb(219, 172, 71);
}

.app__header {
  background-color: var(--color-black);
  /* border: 5px solid red; */
  display: block;
}

.app__header-h1 {
  font-family: (--font-base);
  color: var(--color-golden);
  letter-spacing: 0.04em;
  font-size: 40px;
}

.app__header-img img {
  width: 80%;
}

#exploreMenuButton {
  transition: 0.3s ease-in;
  border-radius: 8px;
}
#exploreMenuButton:hover {
  background-color: rgb(219, 172, 71);
}

.app__chef-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem;
}

.app__chef-content-quote {
  /* display: flex;
  justify-content: flex-start; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.app__chef-content-quote img {
  width: 47px;
  height: 40px;
  margin: 0 1rem 1rem 0;
}

.foto_toBeReplaced {
  background: url("../assets/images/lumare_animation.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
}

.foto_familyDinner {
  background: url("../assets/images/familyDinner.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  /* background-attachment: fixed; */
}

.border {
  border: 2px solid green;
}

.app__laurels_awards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.app__laurels_awards-card {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 230px;
  margin: 1rem;
}

.app__laurels_awards-card img {
  width: 50px;
  height: 50px;
}

.app__laurels_awards-card_content {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

@media screen and (min-width: 1900px) {
  .app__laurels_awards-card {
    min-width: 390px;
  }
}

@media screen and (max-width: 450px) {
  .app__laurels_awards-card {
    min-width: 100%;
    margin: 1rem 0;
  }
}

.customCertificateWrapper img {
  max-width: 480px;
  border: 3px solid var(--color-golden);
  border-radius: 8px;
}

.app__gallery {
  display: flex;
  flex-direction: row;

  background: var(--color-black);
  padding: 4rem 0 4rem 6rem;
}

.app__gallery-content {
  /* border: 1px solid red; */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  min-width: 500px;
  padding-right: 2rem;
}

.app__gallery-images {
  /* border: 1px solid red; */
  flex: 1;
  display: flex;
  flex-direction: row;
  max-width: 50%;
  position: relative;
}

.app__gallery-images_container {
  display: flex;
  flex-direction: row;
  width: max-content;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.app__gallery-images_container::-webkit-scrollbar {
  display: none;
}

.app__gallery-images_card {
  border-radius: 50px;
  position: relative;
  min-width: 320px;
  height: 440px;
  margin-right: 2rem;
}

.gallery__image-icon {
  position: absolute;
  color: #fff;
  opacity: 0.5;
  transition: 0.5s ease;
  cursor: pointer;
}

.app__gallery-images_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: 0.5s ease;
  border-radius: 5px;
}

.app__gallery-images_card:hover img {
  opacity: 0.35;
}

.app__gallery-images_card:hover .gallery__image-icon {
  opacity: 1;
}

.app__gallery-images_arrows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: absolute;
  bottom: 5%;
}

.gallery_arrow-icon {
  color: var(--color-golden);
  font-size: 2rem;
  cursor: pointer;
  background: var(--color-black);
  border-radius: 5px;
}

.gallery_arrow-icon:hover {
  color: var(--color-white);
}

@media screen and (min-width: 2000px) {
  .app__gallery-content {
    min-width: 1000px;
    padding-right: 4rem;
  }

  .app__gallery-images_card {
    min-width: 400px;
    height: 550px;
  }
}

@media screen and (max-width: 1150px) {
  .app__gallery {
    flex-direction: column;
  }
  .app__gallery-images {
    max-width: 100%;
    margin: 5rem 0;
  }
}

@media screen and (max-width: 850px) {
  .app__gallery {
    padding: 4rem 0 4rem 4rem;
  }
}

@media screen and (max-width: 650px) {
  .app__gallery {
    padding: 4rem 0 4rem 2rem;
  }

  .app__gallery-content {
    min-width: 100%;
  }

  .app__gallery-images_card {
    min-width: 260px;
    height: 350px;
  }
}

#notSelectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.menuAdContainer {
  /* border: 1px solid red; */
  width: 100%;
  color: white;
  margin-top: -25px;
}

.divMenuTitle {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuAdPhotos {
  /* border: 1px solid green; */
  margin-top: 45px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 16px;
}

.menuAdd__img {
  border: 1px solid var(--color-golden);
  border-radius: 5px;
  width: 275px;
  margin: 0px;
  /* border: 2px solid red; */
}

@media screen and (max-width: 650px) {
  .menuAdd__img {
    width: 265px;
  }
  .app__footer-links_contact,
  .app__footer-links_logo,
  .app__footer-links_work {
    width: 265px;
  }
}

.starter,
.mainCourse,
.dessert {
  /* border: 5px solid blue; */
  position: relative;
  display: flex;
}

.textInMenuAdd {
  border: 1px solid white;
  border-radius: 5px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  text-align: center;
  transition: 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.85);
  padding-left: 12px;
  padding-right: 12px;
  opacity: 0;
}

.textInMenuAdd:hover {
  opacity: 1;
}

.exploreMenuButtons {
  border: 2px solid white;
  font-size: 16.5px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: transparent;
  border-radius: 15px;
  transition: 0.3s ease-in;
  color: white;
}

.exploreMenuButtons:hover {
  color: black;
  background-color: aquamarine;
}

.exploreMenuButtons:active {
  color: white;
}

.menuAdTitles {
  font-family: "Kalam", cursive;
  font-size: 30px;
}

.customFooterBackground {
  /* border: 5px solid red; */
  background-color: var(--color-black);
  color: white;
}

.app__footer-links {
  /* border: 5px solid blue; */
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 15px;
  /* padding: 0 2rem; */
}

@media screen and (max-width: 1000px) {
  .app__footer-links {
    flex-direction: column;
    /* border: 1px solid blue; */
    align-items: center;
  }
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
  text-align: center;
  /* border: 2px solid red; */
  padding-left: 10px;
  padding-right: 10px;
  width: 275px;
  margin: 0px;
}

#restaurantNameInFooter {
  /* border: 1px solid green; */
  font-size: 45px;
  color: white;
}

.app__footer-links_icons {
  margin-top: 0.5rem;
}

.app__footer-links_icons svg {
  margin: 0.35rem;
  font-size: 25px;
  cursor: pointer;
}

.app__footer-links_icons svg:hover {
  color: var(--color-golden);
}

.app__footer-headtext {
  font-family: var(--font-base);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-size: 32px;
  line-height: 41.6px;
  margin-bottom: 20px;
  /* border: 1px solid green; */
  padding-top: 31px;
}

.footerCreditsCustom {
  width: 100%;
  background-color: var(--color-black);
  display: flex;
  justify-content: center;
  padding: 12.5px;
}

#overrideBackgroundColorr {
  background-color: black;
}

@media screen and (min-width: 775px) {
  .foto_toBeReplaced {
    background-attachment: fixed;
  }
  .foto_familyDinner {
    background-attachment: fixed;
  }
  .content {
    font-family: "Kolom", cursive;
  }
}

@media screen and (max-width: 775px) {
  .foto_toBeReplaced {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: black;
  }
  .foto_familyDinner {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: black;
  }
}
