.notFoundPageWrapper {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 2px solid red;
  border-radius: 10px;
  width: 100%;
  min-width: 300px;
  max-width: 550px;
  /* min-height: 300px; */
  /* min-height: 600px; */
  background-color: black;
  color: white;
  font-size: 18.5px;
  /* background: url('../assets/images/menuBackwob.png');

   
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  padding: 20px;
}

.notFound {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.notFoundHeaderr {
  text-align: center;
  color: red;
}

.notFoundParagraph {
  text-align: center;
  margin-top: 15px;
}

#notFoundReturn {
  margin-top: 15px;
}
